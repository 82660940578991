html {
    height: 100%;
}

body {
    height: 100%;
    background-color: gray;
}

#root {
    height: 100%;
}

.App {
    text-align: center;
}

.links {
    display: flex;
    height: 10%;
    flex-direction: column;
    justify-content: space-around;
    padding: 2em;
}